/** PACKAGE IMPORTS */
import { Auth } from 'aws-amplify';
import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContext, useEffect } from 'react';

/** CONTEXT IMPORTS */
import { cognitoContext } from '../Contexts/cognito';
import { loadingContext } from '../Contexts/loading';

/** UTIL IMPORTS */
import { ConsoleUtils } from '../utils';

const Loading = () => {
  const { dispatch: cognitoDispatch } = useContext(cognitoContext);
  const { dispatch: loadingDispatch } = useContext(loadingContext);

  const classes = useStyles();

  useEffect(() => {
    const checkSignedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        cognitoDispatch({ type: 'SIGN_IN', user, fromLoading: true });
      } catch (e) {
        ConsoleUtils.log(e);
      } finally {
        loadingDispatch({ type: 'FINISH_LOADING' });
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    checkSignedIn();
  }, []);

  return (
    <Grid className={classes.rootGrid} container justify="center" alignItems="center">
      <Paper elevation={6}>
        <Grid className={classes.containerGrid} container direction="column" alignItems="center">
          <CircularProgress />
          <small className={classes.loadingSmall}>Loading...</small>
        </Grid>
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  rootGrid: {
    height: '100vh'
  },
  containerGrid: {
    padding: 16
  },
  loadingSmall: {
    marginTop: 16,
    fontSize: 16
  }
}));

export default Loading;
