/** PACKAGE IMPORTS */
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import { TextField } from 'final-form-material-ui';
import { Field, Form } from 'react-final-form';
// noinspection ES6CheckImport
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

/** GRAPHQL IMPORTS */
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { createParticipant } from '../graphql/mutations';

/** UTIL IMPORTS */
import { ConsoleUtils, CronUtils, YupUtils } from '../utils';

const CREATE_PARTICIPANT_VALIDATE = YupUtils.createValidateFromYupSchema(
  yup.object({
    fullName: yup.string().required('Full name is a required field'),
    phoneNumber: yup
      .string()
      .matches(/^\+\d{11}$/, 'Phone number must use the form: +16145555555')
      .required('Phone number is a required field')
  })
);

const CreateParticipantForm = ({ handleSubmit, submitting }) => {
  const classes = useStyles();
  const history = useHistory();
  const { goBack } = history;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid className={classes.participantFormContainer} container justify="center">
        <Grid item xs={6}>
          <Field fullWidth required component={TextField} type="text" label="Full Name" name="fullName" />
        </Grid>
      </Grid>
      <Grid className={classes.participantFormContainer} container justify="center">
        <Grid item xs={6}>
          <Field fullWidth required component={TextField} type="text" label="Phone Number" name="phoneNumber" />
        </Grid>
      </Grid>
      <Grid className={classes.participantFormButtonContainer} container justify="flex-end" spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary" type="button" disabled={submitting} startIcon={<CloseIcon />} onClick={goBack}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" type="submit" disabled={submitting} startIcon={<DoneIcon />}>
            Create
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const CreateParticipant = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleCreateParticipant = async ({ fullName, phoneNumber }) => {
    try {
      const createParticipantResult = await API.graphql(
        graphqlOperation(createParticipant, { input: { fullName, phoneNumber, status: 'ACTIVE', scheduleCrons: [CronUtils.getInitialCron()] } })
      );
      const newParticipantId = createParticipantResult?.data?.createParticipant?.id ?? null;

      if (newParticipantId) history.push(`/participants/${newParticipantId}`);
      else history.push('/');
    } catch (e) {
      ConsoleUtils.log(e);
    }
  };

  return (
    <Grid className={classes.rootGrid} container justify="center">
      <Grid item xs={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className={classes.contentPaper} elevation={4}>
              <Grid className={classes.sectionHeaderContainerGrid} container justify="center">
                <Typography variant="h4">Create Participant</Typography>
              </Grid>
              <Form validate={CREATE_PARTICIPANT_VALIDATE} render={CreateParticipantForm} onSubmit={handleCreateParticipant} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    rootGrid: {
      marginTop: theme.spacing(4)
    },
    contentPaper: {
      padding: theme.spacing(4)
    },
    participantFormContainer: {
      marginTop: theme.spacing(2)
    },
    participantFormButtonContainer: {
      marginTop: theme.spacing(4)
    }
  };
});

export default CreateParticipant;
