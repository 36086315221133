/** PACKAGE IMPORTS */
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { Amplify, Analytics } from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
// import React from 'react';
import ReactDOM from 'react-dom';

/** VIEW IMPORTS */
import App from './Views/App';

/** CONTEXT IMPORTS */
import { CognitoProvider } from './Contexts/cognito';
import { LoadingProvider } from './Contexts/loading';

/** UTIL IMPORTS */
import { WebVitalUtils } from './utils';

/** CONFIG IMPORTS */
import AWS_EXPORTS from './aws-exports';

/** ASSET IMPORTS */
import LIGHT_PALETTE from './assets/palettes';

/** STYLE IMPORTS */
import 'fontsource-roboto';
import './index.css';

/** CONSTANTS */
const AWS_ENV = 'prod';

/** Configure Amplify */
Amplify.configure(AWS_EXPORTS[AWS_ENV]);
Analytics.configure({ disabled: AWS_ENV === 'dev' });

/** Create dark theme */
// const darkTheme = createMuiTheme({ palette: { type: 'dark' } });
const lightTheme = createMuiTheme({
  palette: LIGHT_PALETTE,
  spacing: 8,
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em'
      }
    }
  }
});

ReactDOM.render(
  // <React.StrictMode>
  <LoadingProvider>
    <CognitoProvider>
      <ThemeProvider theme={lightTheme}>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <CssBaseline />
          <SnackbarProvider autoHideDuration={2500} maxSnack={3}>
            <App />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </CognitoProvider>
  </LoadingProvider>,
  // </React.StrictMode>,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
WebVitalUtils.reportWebVitals();
