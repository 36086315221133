/* PACKAGE IMPORTS */
import { TextField } from '@material-ui/core';
import { TimePicker as BaseTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useEffect, useState } from 'react';

/* CONSTANTS */
const DEFAULT_INITIAL_TIME = '15:00';
const TEXT_FIELD_PLACEHOLDER = 'Time';
const NO_OP = () => {};

/**
 * Valid times - end in [00, 15, 30, 45]
 * Default - 15:00 (3 pm)
 */
const TimePicker = ({ onAccept = NO_OP, initialTime = DEFAULT_INITIAL_TIME }) => {
  const [time, setTime] = useState(initialTime);
  const timeMoment = moment(time, 'HH:mm');
  const isTimeValid = [0, 15, 30, 45].includes(timeMoment.minutes());

  useEffect(() => (isTimeValid && time !== initialTime ? onAccept(time) : NO_OP()), [isTimeValid, onAccept, time]);

  const handleChange = (newTimeMoment) => {
    if (newTimeMoment) setTime(newTimeMoment.format('HH:mm'));
    else setTime(null);
  };

  const renderInput = (props) => {
    props.InputProps.placeholder = TEXT_FIELD_PLACEHOLDER;
    props.inputProps.placeholder = TEXT_FIELD_PLACEHOLDER;

    return <TextField {...props} helperText={isTimeValid ? 'Looks good!' : 'Time must end in [00, 15, 30, 45]'} error={!isTimeValid} />;
  };

  return <BaseTimePicker minutesStep={15} value={timeMoment} renderInput={renderInput} onChange={handleChange} />;
};

export { DEFAULT_INITIAL_TIME };
export default TimePicker;
