/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      text
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      id
      text
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      id
      text
      createdAt
      updatedAt
    }
  }
`;
export const createMessageSet = /* GraphQL */ `
  mutation CreateMessageSet($input: CreateMessageSetInput!) {
    createMessageSet(input: $input) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageSet = /* GraphQL */ `
  mutation UpdateMessageSet($input: UpdateMessageSetInput!) {
    updateMessageSet(input: $input) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageSet = /* GraphQL */ `
  mutation DeleteMessageSet($input: DeleteMessageSetInput!) {
    deleteMessageSet(input: $input) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createParticipant = /* GraphQL */ `
  mutation CreateParticipant($input: CreateParticipantInput!) {
    createParticipant(input: $input) {
      id
      fullName
      phoneNumber
      status
      scheduleCrons
      lastMessageRecordId
      createdAt
      updatedAt
    }
  }
`;
export const updateParticipant = /* GraphQL */ `
  mutation UpdateParticipant($input: UpdateParticipantInput!) {
    updateParticipant(input: $input) {
      id
      fullName
      phoneNumber
      status
      scheduleCrons
      lastMessageRecordId
      createdAt
      updatedAt
    }
  }
`;
export const deleteParticipant = /* GraphQL */ `
  mutation DeleteParticipant($input: DeleteParticipantInput!) {
    deleteParticipant(input: $input) {
      id
      fullName
      phoneNumber
      status
      scheduleCrons
      lastMessageRecordId
      createdAt
      updatedAt
    }
  }
`;
export const createMessageSetMessage = /* GraphQL */ `
  mutation CreateMessageSetMessage($input: CreateMessageSetMessageInput!) {
    createMessageSetMessage(input: $input) {
      id
      messageId
      messageSetId
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageSetMessage = /* GraphQL */ `
  mutation UpdateMessageSetMessage($input: UpdateMessageSetMessageInput!) {
    updateMessageSetMessage(input: $input) {
      id
      messageId
      messageSetId
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageSetMessage = /* GraphQL */ `
  mutation DeleteMessageSetMessage($input: DeleteMessageSetMessageInput!) {
    deleteMessageSetMessage(input: $input) {
      id
      messageId
      messageSetId
      createdAt
      updatedAt
    }
  }
`;
export const createMessageSetSubscription = /* GraphQL */ `
  mutation CreateMessageSetSubscription(
    $input: CreateMessageSetSubscriptionInput!
  ) {
    createMessageSetSubscription(input: $input) {
      id
      participantId
      messageSetId
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageSetSubscription = /* GraphQL */ `
  mutation UpdateMessageSetSubscription(
    $input: UpdateMessageSetSubscriptionInput!
  ) {
    updateMessageSetSubscription(input: $input) {
      id
      participantId
      messageSetId
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageSetSubscription = /* GraphQL */ `
  mutation DeleteMessageSetSubscription(
    $input: DeleteMessageSetSubscriptionInput!
  ) {
    deleteMessageSetSubscription(input: $input) {
      id
      participantId
      messageSetId
      createdAt
      updatedAt
    }
  }
`;
export const createMessageRecord = /* GraphQL */ `
  mutation CreateMessageRecord($input: CreateMessageRecordInput!) {
    createMessageRecord(input: $input) {
      id
      status
      participantFullName
      participantPhoneNumber
      messageText
      participantId
      messageId
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageRecord = /* GraphQL */ `
  mutation UpdateMessageRecord($input: UpdateMessageRecordInput!) {
    updateMessageRecord(input: $input) {
      id
      status
      participantFullName
      participantPhoneNumber
      messageText
      participantId
      messageId
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageRecord = /* GraphQL */ `
  mutation DeleteMessageRecord($input: DeleteMessageRecordInput!) {
    deleteMessageRecord(input: $input) {
      id
      status
      participantFullName
      participantPhoneNumber
      messageText
      participantId
      messageId
      createdAt
      updatedAt
    }
  }
`;
