/** PACKAGE IMPORTS */
import { Box, Button, Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
// noinspection ES6CheckImport
import { useHistory, useParams } from 'react-router-dom';

/** PACKAGE ICON IMPORTS */
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

/** COMPONENT IMPORTS */
import { ScheduleCronPicker } from '../Components';

/** HOOK IMPORTS */
import { useRemoveMultiSelectButtonHack } from '../hooks';

/** UTIL IMPORTS */
import { AmplifyUtils, CronUtils, DataGridUtils } from '../utils';

/** GRAPHQL IMPORTS */
import { getParticipant } from '../graphql/queries';
import { createMessageSetSubscription, deleteMessageSetSubscription, deleteParticipant, updateParticipant } from '../graphql/mutations';

/** CONSTANTS */
const TABS = {
  SUBSCRIPTIONS: 0,
  SCHEDULES: 1,
  HISTORY: 2
};

const ParticipantDetails = () => {
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { id } = useParams();

  const [participant, setParticipant] = useState({});
  const [messageSets, setMessageSets] = useState([]);
  const [selectedMessageSetIds, setSelectedMessageSetIds] = useState([]);
  const [messageRecords, setMessageRecords] = useState([]);
  const [tabValue, setTabValue] = useState(TABS.SUBSCRIPTIONS);
  const [isLoading, setIsLoading] = useState(false);
  const [, setIsDeleting] = useState(false);
  const [, setIsTogglingStatus] = useState(false);

  /** Bug-fix resulting from trying to use the DataGrid the way I am */
  const [firstWarningHidden, setFirstWarningHidden] = useState(false);

  const { fullName = 'Unknown name', phoneNumber = 'Unknown phone number', scheduleCrons = [], status } = participant ?? {};
  const areScheduleCronsAtMaximum = CronUtils.areScheduleCronsAtMaximum(scheduleCrons);
  const isActive = status === 'ACTIVE';

  const fetchParticipant = async () => {
    try {
      const getParticipantResult = await API.graphql(graphqlOperation(getParticipant, { id }));
      setParticipant(getParticipantResult?.data?.getParticipant ?? []);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchMessageSets = async () => AmplifyUtils.API.listAll('listMessageSets').then(setMessageSets);

  const fetchSelectedMessageSets = async () => {
    try {
      const messageSetSubscriptions = await AmplifyUtils.API.listAll('listMessageSetSubscriptions', { participantId: { eq: id } });
      setSelectedMessageSetIds(messageSetSubscriptions.map(({ messageSetId }) => messageSetId));
    } catch (e) {
      console.log(e);
    }
  };

  const fetchMessageRecords = async () => AmplifyUtils.API.listAll('listMessageRecords', { participantId: { eq: id } }).then(setMessageRecords);

  useEffect(fetchParticipant, [id]);
  useEffect(fetchMessageSets, []);
  useEffect(fetchSelectedMessageSets, []);
  useEffect(fetchMessageRecords, []);

  /* Use hack to remove multi-select button */
  useRemoveMultiSelectButtonHack([tabValue]);

  const handleTabValueChange = (_, newTabValue) => {
    setTabValue(newTabValue);
    setFirstWarningHidden(false);
  };

  const handleSelectionModelChange = async ({ selectionModel: newSelectedMessageSetIds }) => {
    try {
      setIsLoading(true);

      /* ADDITION: Create new {MessageSetSubscription} */
      if (newSelectedMessageSetIds.length - selectedMessageSetIds.length === 1) {
        const filteredNewSelectedMessageSetIds = newSelectedMessageSetIds.filter(
          (newSelectedMessageSetId) => !selectedMessageSetIds.includes(newSelectedMessageSetId)
        );

        /* Create new {MessageSetMessage} for each id in {filteredNewSelectedMessageSetIds} */
        // eslint-disable-next-line no-restricted-syntax
        for (const messageSetId of filteredNewSelectedMessageSetIds) {
          // eslint-disable-next-line no-await-in-loop
          await API.graphql(graphqlOperation(createMessageSetSubscription, { input: { messageSetId, participantId: id } }));
        }
      } else if (selectedMessageSetIds.length - newSelectedMessageSetIds.length === 1) {
        /* SUBTRACTION: Delete old {MessageSetMessage} */
        const filteredOldSelectedMessageSetIds = selectedMessageSetIds.filter(
          (selectedMessageSetId) => !newSelectedMessageSetIds.includes(selectedMessageSetId)
        );

        /* Delete old {MessageSetMessage} for each id in {filteredOldSelectedMessageSetIds} */
        // eslint-disable-next-line no-restricted-syntax
        for (const messageSetId of filteredOldSelectedMessageSetIds) {
          /* Get all {MessageSetMessage} with both {messageId} and {messageId} */
          // eslint-disable-next-line no-await-in-loop
          const messageSetSubscriptionsToDelete = await AmplifyUtils.API.listAll('listMessageSetSubscriptions', {
            messageSetId: { eq: messageSetId },
            participantId: { eq: id }
          });

          /* For each {MessageSetMessage} found, delete it */
          // eslint-disable-next-line no-restricted-syntax
          for (const { id: messageSetSubscriptionsId } of messageSetSubscriptionsToDelete) {
            // eslint-disable-next-line no-await-in-loop
            await API.graphql(graphqlOperation(deleteMessageSetSubscription, { input: { id: messageSetSubscriptionsId } }));
          }
        }
      } else if (firstWarningHidden) {
        enqueueSnackbar('Multi-select is not enabled, please refresh your page.', { variant: 'warning' });
        await fetchSelectedMessageSets();
      }

      /* Update {selectedMessageSetIds} */
      setSelectedMessageSetIds(newSelectedMessageSetIds);
      setFirstWarningHidden(true);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleParticipantStatusClick = async () => {
    try {
      setIsTogglingStatus(true);

      await API.graphql(graphqlOperation(updateParticipant, { input: { id, status: isActive ? 'PAUSED' : 'ACTIVE' } }));
      await fetchParticipant();
    } catch (e) {
      console.log(e);
    } finally {
      setIsTogglingStatus(true);
    }
  };

  const handleDeleteParticipantClick = async () => {
    try {
      setIsDeleting(true);

      /* Delete {participant} */
      await API.graphql(graphqlOperation(deleteParticipant, { input: { id } }));

      /* Get {MessageSetSubscription} connected to {participant} and delete them */
      const messageSetSubscriptionsToDelete = await AmplifyUtils.API.listAll('listMessageSetSubscriptions', { participantId: { eq: id } });

      for (const messageSetSubscriptionToDelete of messageSetSubscriptionsToDelete) {
        const { id: messageSetSubscriptionIdToDelete = null } = messageSetSubscriptionToDelete;

        if (messageSetSubscriptionIdToDelete)
          API.graphql(graphqlOperation(deleteMessageSetSubscription, { input: { id: messageSetSubscriptionIdToDelete } }));
      }

      history.replace('/');
    } catch (e) {
      console.log(e);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleAddScheduleCronClick = async () => {
    try {
      if (Array.isArray(scheduleCrons))
        await API.graphql(graphqlOperation(updateParticipant, { input: { id, scheduleCrons: [...scheduleCrons, CronUtils.getInitialCron()] } }));
      else await API.graphql(graphqlOperation(updateParticipant, { input: { id, scheduleCrons: [CronUtils.getInitialCron()] } }));
      await fetchParticipant();
    } catch (e) {
      console.log(e);
    }
  };

  /* Note: we can assume that {scheduleCrons} is an array since we are editing an item from it */
  const handleScheduleCronChange = async (newScheduleCron, index) => {
    try {
      const newScheduleCrons = [...scheduleCrons];
      newScheduleCrons[index] = newScheduleCron;

      await API.graphql(graphqlOperation(updateParticipant, { input: { id, scheduleCrons: newScheduleCrons } }));
      await fetchParticipant();
    } catch (e) {
      console.log(e);
    }
  };

  /* Note: we can assume that {scheduleCrons} is an array since an item called the function */
  const handleDeleteScheduleCronByIndex = async (index) => {
    try {
      const newScheduleCrons = [...scheduleCrons];
      newScheduleCrons.splice(index, 1);

      await API.graphql(graphqlOperation(updateParticipant, { input: { id, scheduleCrons: newScheduleCrons } }));
      await fetchParticipant();
    } catch (e) {
      console.log(e);
    }
  };

  const renderScheduleCron = (scheduleCron, index) => {
    return (
      <Grid key={Date.now() + index} item xs={12}>
        <ScheduleCronPicker
          initialValue={scheduleCron}
          addDaysDisabled={areScheduleCronsAtMaximum}
          onChange={(newScheduleCron) => handleScheduleCronChange(newScheduleCron, index)}
          onDelete={() => handleDeleteScheduleCronByIndex(index)}
        />
      </Grid>
    );
  };

  if (id)
    return (
      <Grid className={classes.rootGrid} container justify="center">
        <Grid item xs={12} lg={8}>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Paper className={classes.contentPaper} elevation={4}>
                <Grid container justify="flex-start">
                  <Grid className={classes.sectionHeaderGridItem} item xs={12}>
                    <Grid className={classes.sectionHeaderContainerGrid} container>
                      <Typography variant="h4">Participant Details</Typography>
                      {/* Only show buttons if participant is ACTIVE or PAUSED
                       *   - OPTED_OUT participants cannot be deleted or resumed
                       */}
                      {['ACTIVE', 'PAUSED'].includes(status) && (
                        <Grid item>
                          <Button
                            className={classes.changeStatusButton}
                            color="primary"
                            variant="contained"
                            endIcon={isActive ? <PauseIcon /> : <PlayArrowIcon />}
                            onClick={handleToggleParticipantStatusClick}
                          >
                            {isActive ? 'Pause' : 'Resume'}
                          </Button>
                          <Button color="secondary" variant="contained" endIcon={<DeleteForeverIcon />} onClick={handleDeleteParticipantClick}>
                            Delete
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                    <Typography variant="caption">{`ID: ${id}`}</Typography>
                    <Box fontStyle="italic" component={Typography} variant="h6">{`Name: ${fullName}`}</Box>
                    <Box fontStyle="italic" component={Typography} variant="body1">{`Phone Number: ${phoneNumber}`}</Box>
                    <Box fontStyle="italic" component={Typography} variant="body1">{`Status: ${status}`}</Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Tabs value={tabValue} onChange={handleTabValueChange} variant="fullWidth" indicatorColor="primary">
                      <Tab label="Subscriptions" />
                      <Tab label="Schedule" />
                      <Tab label="History" />
                    </Tabs>
                    {tabValue === TABS.SUBSCRIPTIONS && (
                      <DataGrid
                        className={classes.sectionDataGrid}
                        rows={messageSets}
                        columns={DataGridUtils.MESSAGE_SET_COLUMNS}
                        autoHeight
                        checkboxSelection
                        loading={isLoading}
                        pageSize={10}
                        selectionModel={selectedMessageSetIds}
                        onSelectionModelChange={handleSelectionModelChange}
                        disableSelectionOnClick
                      />
                    )}
                    {tabValue === TABS.SCHEDULES && (
                      <Grid className={classes.scheduleTabContainerGrid} container justify="center">
                        {scheduleCrons && scheduleCrons.map(renderScheduleCron)}
                        <Button
                          className={classes.addScheduleButton}
                          variant="contained"
                          color="primary"
                          disabled={areScheduleCronsAtMaximum}
                          endIcon={<AddCircleOutlineRoundedIcon />}
                          onClick={handleAddScheduleCronClick}
                        >
                          Add Schedule
                        </Button>
                      </Grid>
                    )}
                    {tabValue === TABS.HISTORY && (
                      <DataGrid
                        className={classes.sectionDataGrid}
                        rows={messageRecords}
                        columns={DataGridUtils.PARTICIPANT_MESSAGE_RECORD_COLUMNS}
                        autoHeight
                        loading={isLoading}
                        pageSize={10}
                        components={{ Toolbar: GridToolbar }}
                        disableSelectionOnClick
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  return null;
};

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    margin: theme.spacing(2),
    width: `calc(100vw - ${theme.spacing(4)}px)`
  },
  sectionHeaderGridItem: {
    padding: theme.spacing(2)
  },
  sectionHeaderContainerGrid: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  changeStatusButton: {
    marginRight: theme.spacing(2)
  },
  /* Schedules */
  addScheduleButton: {
    // marginTop: theme.spacing(4)
  },
  scheduleTabContainerGrid: {
    padding: theme.spacing(4)
  }
}));

export default ParticipantDetails;
