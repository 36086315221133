/** PACKAGE IMPORTS */
import { createContext, useReducer } from 'react';

/** Initialize and create context */
const INITIAL_STATE = { fromLoading: false, isSignedIn: false, user: null };
const cognitoContext = createContext(INITIAL_STATE);
const { Provider } = cognitoContext;

const CognitoProvider = ({ children }) => {
  const initialState = { ...INITIAL_STATE };

  const [state, dispatch] = useReducer((prevState, { fromLoading = false, user, type }) => {
    switch (type) {
      case 'CHANGE_PASSWORD':
        if (user) return { ...prevState, fromLoading: false, isSignedIn: false, user };
        throw new Error('user not supplied to dispatch of type CHANGE_PASSWORD.');
      case 'SIGN_IN':
        if (user) return { ...prevState, fromLoading, isSignedIn: true, user };
        throw new Error('user not supplied to dispatch of type SIGN_IN.');
      case 'SIGN_OUT':
        return initialState;
      default:
        throw new Error('type not supplied to dispatch.');
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { cognitoContext, CognitoProvider };
