const error = (message) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') console.error(message);
};

const log = (message) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') console.log(message);
};

export default { error, log };
