/** PACKAGE IMPORTS */
import { useEffect } from 'react';

/** UTIL IMPORTS */
import { ConsoleUtils } from '../utils';

const useRemoveMultiSelectButtonHack = (dependencies) =>
  useEffect(() => {
    setTimeout(() => {
      try {
        // eslint-disable-next-line no-undef
        const elements = document.getElementsByClassName('MuiDataGrid-columnHeaderCheckbox');
        if (elements.length > 0) elements[0].removeChild(elements[0].children[0]);
      } catch (e) {
        ConsoleUtils.log(e);
      }
    }, 0);
  }, dependencies);

export default useRemoveMultiSelectButtonHack;
