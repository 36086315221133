/** PACKAGES */
import { API, graphqlOperation } from 'aws-amplify';

/** QUERIES */
import * as GRAPHQL_QUERIES from '../graphql/queries';

/** -----API----- */
const listAll = async (listQueryName, filter) => {
  const listQuery = GRAPHQL_QUERIES?.[listQueryName] ?? null;
  if (!listQuery) throw new Error(`No query found matching name ${listQueryName}`);

  const items = [];
  let nextToken = null;
  let firstQuery = true;

  while (firstQuery || nextToken) {
    // eslint-disable-next-line no-await-in-loop
    const listResults = await API.graphql(graphqlOperation(listQuery, { filter, limit: 1000, nextToken }));
    const newNextToken = listResults?.data?.[listQueryName]?.nextToken ?? null;
    const newItems = listResults?.data?.[listQueryName]?.items ?? [];

    /* Failsafe to prevent infinite loop */
    if (!firstQuery && nextToken === newNextToken) throw new Error('Duplicate nextToken encountered');

    /* Add {newItems} to {items}, update {nextToken}, set {firstQuery} to false (do-while) */
    nextToken = newNextToken;
    items.push(...newItems);
    firstQuery = false;
  }

  return items;
};

export default {
  API: {
    listAll
  }
};
