import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const LinkButton = ({ children, color, endIcon, variant, startIcon, to }) => (
  <Button component={Link} color={color} variant={variant} startIcon={startIcon} endIcon={endIcon} to={to}>
    {children}
  </Button>
);

export default LinkButton;
