/** PACKAGE IMPORTS */
import { createContext, useReducer } from 'react';

/** Initialize and create context */
const INITIAL_STATE = { isLoading: true };
const loadingContext = createContext(INITIAL_STATE);
const { Provider } = loadingContext;

const LoadingProvider = ({ children }) => {
  const [state, dispatch] = useReducer((prevState, { type }) => {
    if (type === 'FINISH_LOADING') return { ...prevState, isLoading: false };
    throw new Error();
  }, INITIAL_STATE);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { loadingContext, LoadingProvider };
