/** PACKAGE IMPORTS */
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import { TextField } from 'final-form-material-ui';
import { Field, Form } from 'react-final-form';
// noinspection ES6CheckImport
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

/** ICON PACKAGE IMPORTS */
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

/** GRAPHQL IMPORTS */
import { createMessage } from '../graphql/mutations';

/** UTIL IMPORTS */
import { ConsoleUtils, YupUtils } from '../utils';

const CREATE_MESSAGE_VALIDATE = YupUtils.createValidateFromYupSchema(
  yup.object({
    text: yup.string().required()
  })
);

const CreateMessageForm = ({ handleSubmit, submitting }) => {
  const classes = useStyles();
  const history = useHistory();
  const { goBack } = history;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container>
        <Grid className={classes.messageFormTextField} item xs={12}>
          <Field fullWidth required component={TextField} type="text" label="Text" name="text" multiline rows={10} variant="outlined" />
        </Grid>
      </Grid>
      <Grid className={classes.signInFormContainerGrid} container justify="flex-end" spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary" type="button" disabled={submitting} startIcon={<CloseIcon />} onClick={goBack}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" type="submit" disabled={submitting} startIcon={<DoneIcon />}>
            Create
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const CreateMessage = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleCreateMessage = async ({ text }) => {
    try {
      const createMessageResult = await API.graphql(graphqlOperation(createMessage, { input: { text } }));
      const { id } = createMessageResult?.data?.createMessage ?? {};

      if (id) history.push(`/messages/${id}`);
      else history.goBack();
    } catch (e) {
      ConsoleUtils.log(e);
    }
  };

  return (
    <Grid className={classes.rootGrid} container justify="center">
      <Grid item xs={6}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12}>
            <Paper className={classes.contentPaper} elevation={4}>
              <Grid className={classes.sectionHeaderContainerGrid} container justify="center">
                <Typography variant="h4">Create Message</Typography>
              </Grid>
              <Form validate={CREATE_MESSAGE_VALIDATE} render={CreateMessageForm} onSubmit={handleCreateMessage} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    rootGrid: {
      marginTop: theme.spacing(4)
    },
    contentPaper: {
      padding: theme.spacing(4)
    },
    messageFormTextField: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2)
    }
  };
});

export default CreateMessage;
