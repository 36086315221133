/** PACKAGE IMPORTS */
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
// import { Form } from 'react-final-form';
// noinspection ES6CheckImport
import { useHistory, useParams } from 'react-router-dom';

/** ICON IMPORTS */
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

/** UTIL IMPORTS */
import { AmplifyUtils, DataGridUtils } from '../utils';

/** GRAPHQL IMPORTS */
import { getMessage } from '../graphql/queries';
import { createMessageSetMessage, deleteMessage, deleteMessageSetMessage } from '../graphql/mutations';
import { useRemoveMultiSelectButtonHack } from '../hooks';

const MessageDetails = () => {
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { id } = useParams();

  const [message, setMessage] = useState([]);
  const [messageSets, setMessageSets] = useState([]);
  const [selectedMessageSetIds, setSelectedMessageSetIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [firstWarningHidden, setFirstWarningHidden] = useState(false);
  const { text = 'No message found' } = message ?? {};

  const fetchMessage = async () => {
    try {
      const getMessageResult = await API.graphql(graphqlOperation(getMessage, { id }));
      setMessage(getMessageResult?.data?.getMessage ?? []);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchMessageSets = async () => AmplifyUtils.API.listAll('listMessageSets').then(setMessageSets);

  const fetchSelectedMessageSets = async () => {
    try {
      const messageSetMessages = await AmplifyUtils.API.listAll('listMessageSetMessages', { messageId: { eq: id } });
      setSelectedMessageSetIds(messageSetMessages.map(({ messageSetId }) => messageSetId));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(fetchMessage, [id]);
  useEffect(fetchMessageSets, []);
  useEffect(fetchSelectedMessageSets, []);

  /* Use hack to remove multi-select button */
  useRemoveMultiSelectButtonHack();

  const handleSelectionModelChange = async ({ selectionModel: newSelectedMessageSetIds }) => {
    try {
      setIsLoading(true);

      /* ADDITION: Create new {MessageSetMessage} */
      if (newSelectedMessageSetIds.length - selectedMessageSetIds.length === 1) {
        const filteredNewSelectedMessageSetIds = newSelectedMessageSetIds.filter(
          (newSelectedMessageSetId) => !selectedMessageSetIds.includes(newSelectedMessageSetId)
        );

        /* Create new {MessageSetMessage} for each id in {filteredNewSelectedMessageSetIds} */
        // eslint-disable-next-line no-restricted-syntax
        for (const messageSetId of filteredNewSelectedMessageSetIds) {
          // eslint-disable-next-line no-await-in-loop
          await API.graphql(graphqlOperation(createMessageSetMessage, { input: { messageId: id, messageSetId } }));
        }
      } else if (selectedMessageSetIds.length - newSelectedMessageSetIds.length === 1) {
        /* SUBTRACTION: Delete old {MessageSetMessage} */
        const filteredOldSelectedMessageSetIds = selectedMessageSetIds.filter(
          (selectedMessageSetId) => !newSelectedMessageSetIds.includes(selectedMessageSetId)
        );

        /* Delete old {MessageSetMessage} for each id in {filteredOldSelectedMessageSetIds} */
        // eslint-disable-next-line no-restricted-syntax
        for (const messageSetId of filteredOldSelectedMessageSetIds) {
          /* Get all {MessageSetMessage} with both {messageId} and {messageSetId} */
          // eslint-disable-next-line no-await-in-loop
          const messageSetMessagesToDelete = await AmplifyUtils.API.listAll('listMessageSetMessages', {
            messageId: { eq: id },
            messageSetId: { eq: messageSetId }
          });

          /* For each {MessageSetMessage} found, delete it */
          // eslint-disable-next-line no-restricted-syntax
          for (const { id: messageSetMessageId } of messageSetMessagesToDelete) {
            // eslint-disable-next-line no-await-in-loop
            await API.graphql(graphqlOperation(deleteMessageSetMessage, { input: { id: messageSetMessageId } }));
          }
        }
      } else if (firstWarningHidden) enqueueSnackbar('Multi-select is not enabled, please refresh your page.', { variant: 'warning' });

      /* Update {selectedMessageSetIds} */
      setSelectedMessageSetIds(newSelectedMessageSetIds);
      setFirstWarningHidden(true);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteMessageClick = async () => {
    try {
      await API.graphql(graphqlOperation(deleteMessage, { input: { id } }));

      /* Get {MessageSetMessage} connected to {message} and delete them */
      const messageSetMessagesToDelete = await AmplifyUtils.API.listAll('listMessageSetMessages', { messageId: { eq: id } });

      for (const messageSetMessageToDelete of messageSetMessagesToDelete) {
        const { id: messageSetMessageIdToDelete = null } = messageSetMessageToDelete;

        if (messageSetMessageIdToDelete) API.graphql(graphqlOperation(deleteMessageSetMessage, { input: { id: messageSetMessageIdToDelete } }));
      }

      history.replace('/');
    } catch (e) {
      console.log(e);
    }
  };

  if (id)
    return (
      <Grid className={classes.rootGrid} container justify="center">
        <Grid item xs={8}>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Paper className={classes.contentPaper} elevation={4}>
                <Grid container justify="flex-start">
                  <Grid className={classes.sectionHeaderGridItem} item xs={12}>
                    <Grid className={classes.sectionHeaderContainerGrid} container>
                      <Typography variant="h4">Message Details</Typography>
                      <Grid item>
                        <Button color="secondary" variant="contained" endIcon={<DeleteForeverIcon />} onClick={handleDeleteMessageClick}>
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                    <Typography variant="caption">{`ID: ${id}`}</Typography>
                    <Box fontStyle="italic" component={Typography} variant="h6">{`Message: ${text}`}</Box>
                  </Grid>
                  <Grid className={classes.sectionHeaderGridItem} item xs={12}>
                    <Typography variant="h4">Message Sets Included In:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DataGrid
                      className={classes.sectionDataGrid}
                      rows={messageSets}
                      columns={DataGridUtils.MESSAGE_SET_COLUMNS}
                      autoHeight
                      checkboxSelection
                      loading={isLoading}
                      pageSize={10}
                      selectionModel={selectedMessageSetIds}
                      onSelectionModelChange={handleSelectionModelChange}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  return null;
};

const useStyles = makeStyles((theme) => {
  return {
    rootGrid: {
      margin: theme.spacing(2)
    },
    sectionHeaderGridItem: {
      padding: theme.spacing(2)
    },
    sectionHeaderContainerGrid: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  };
});

export default MessageDetails;
