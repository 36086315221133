/** PACKAGE IMPORTS */
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
// import { Form } from 'react-final-form';
// noinspection ES6CheckImport
import { useHistory, useParams } from 'react-router-dom';

/** ICON IMPORTS */
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

/** HOOK IMPORTS */
import { useRemoveMultiSelectButtonHack } from '../hooks';

/** UTIL IMPORTS */
import { AmplifyUtils, DataGridUtils } from '../utils';

/** GRAPHQL IMPORTS */
import { getMessageSet } from '../graphql/queries';
import { createMessageSetMessage, deleteMessageSet, deleteMessageSetMessage, deleteMessageSetSubscription } from '../graphql/mutations';

const MessageDetails = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { id } = useParams();

  const [messageSet, setMessageSet] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedMessageIds, setSelectedMessageIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [firstWarningHidden, setFirstWarningHidden] = useState(false);
  const { name = 'Unknown name', description = 'Unknown description' } = messageSet ?? {};

  const fetchMessageSet = async () => {
    try {
      const getMessageSetResult = await API.graphql(graphqlOperation(getMessageSet, { id }));
      setMessageSet(getMessageSetResult?.data?.getMessageSet ?? []);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchMessages = async () => {
    try {
      const newMessages = await AmplifyUtils.API.listAll('listMessages');
      setMessages(newMessages);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchSelectedMessages = async () => {
    try {
      const messageSetMessages = await AmplifyUtils.API.listAll('listMessageSetMessages', { messageSetId: { eq: id } });
      setSelectedMessageIds(messageSetMessages.map(({ messageId }) => messageId));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(fetchMessageSet, [id]);
  useEffect(fetchMessages, []);
  useEffect(fetchSelectedMessages, []);

  /* Use hack to remove multi-select button */
  useRemoveMultiSelectButtonHack();

  const handleSelectionModelChange = async ({ selectionModel: newSelectedMessageIds }) => {
    try {
      setIsLoading(true);

      /* ADDITION: Create new {MessageSetMessage} */
      if (newSelectedMessageIds.length - selectedMessageIds.length === 1) {
        const filteredNewSelectedMessageIds = newSelectedMessageIds.filter(
          (newSelectedMessageId) => !selectedMessageIds.includes(newSelectedMessageId)
        );

        /* Create new {MessageSetMessage} for each id in {filteredNewSelectedMessageIds} */
        // eslint-disable-next-line no-restricted-syntax
        for (const messageId of filteredNewSelectedMessageIds) {
          // eslint-disable-next-line no-await-in-loop
          await API.graphql(graphqlOperation(createMessageSetMessage, { input: { messageSetId: id, messageId } }));
        }
      } else if (selectedMessageIds.length - newSelectedMessageIds.length === 1) {
        /* SUBTRACTION: Delete old {MessageSetMessage} */
        const filteredOldSelectedMessageIds = selectedMessageIds.filter((selectedMessageId) => !newSelectedMessageIds.includes(selectedMessageId));

        /* Delete old {MessageSetMessage} for each id in {filteredOldSelectedMessageSetIds} */
        // eslint-disable-next-line no-restricted-syntax
        for (const messageId of filteredOldSelectedMessageIds) {
          /* Get all {MessageSetMessage} with both {messageId} and {messageId} */
          // eslint-disable-next-line no-await-in-loop
          const messageSetMessagesToDelete = await AmplifyUtils.API.listAll('listMessageSetMessages', {
            messageSetId: { eq: id },
            messageId: { eq: messageId }
          });

          /* For each {MessageSetMessage} found, delete it */
          // eslint-disable-next-line no-restricted-syntax
          for (const { id: messageSetMessageId } of messageSetMessagesToDelete) {
            // eslint-disable-next-line no-await-in-loop
            await API.graphql(graphqlOperation(deleteMessageSetMessage, { input: { id: messageSetMessageId } }));
          }
        }
      } else if (firstWarningHidden) enqueueSnackbar('Multi-select is not enabled, please refresh your page.', { variant: 'warning' });

      /* Update {selectedMessageIds} */
      setSelectedMessageIds(newSelectedMessageIds);
      setFirstWarningHidden(true);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteMessageSetClick = async () => {
    try {
      await API.graphql(graphqlOperation(deleteMessageSet, { input: { id } }));

      /* Get {MessageSetSubscription} connected to {messageSet} and delete them */
      const messageSetSubscriptionsToDelete = await AmplifyUtils.API.listAll('listMessageSetSubscriptions', { messageSetId: { eq: id } });

      for (const messageSetSubscriptionToDelete of messageSetSubscriptionsToDelete) {
        const { id: messageSetSubscriptionIdToDelete = null } = messageSetSubscriptionToDelete;

        if (messageSetSubscriptionIdToDelete)
          API.graphql(graphqlOperation(deleteMessageSetSubscription, { input: { id: messageSetSubscriptionIdToDelete } }));
      }

      /* Get {MessageSetMessage} connected to {messageSet} and delete them */
      const messageSetMessagesToDelete = await AmplifyUtils.API.listAll('listMessageSetMessages', { messageSetId: { eq: id } });

      for (const messageSetMessageToDelete of messageSetMessagesToDelete) {
        const { id: messageSetMessageIdToDelete = null } = messageSetMessageToDelete;

        if (messageSetMessageIdToDelete) API.graphql(graphqlOperation(deleteMessageSetMessage, { input: { id: messageSetMessageIdToDelete } }));
      }

      history.replace('/');
    } catch (e) {
      console.log(e);
    }
  };

  if (id)
    return (
      <Grid className={classes.rootGrid} container justify="center">
        <Grid item xs={8}>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Paper className={classes.contentPaper} elevation={4}>
                <Grid container justify="flex-start">
                  <Grid className={classes.sectionHeaderItemGrid} item xs={12}>
                    <Grid className={classes.sectionHeaderContainerGrid} container>
                      <Typography variant="h4">Message Set Details</Typography>
                      <Grid item>
                        <Button color="secondary" variant="contained" endIcon={<DeleteForeverIcon />} onClick={handleDeleteMessageSetClick}>
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                    <Typography variant="caption">{`ID: ${id}`}</Typography>
                    <Box fontStyle="italic" component={Typography} variant="h6">{`Name: ${name}`}</Box>
                    <Box fontStyle="italic" component={Typography} variant="body1">{`Description: ${description}`}</Box>
                  </Grid>
                  <Grid className={classes.sectionHeaderItemGrid} item xs={12}>
                    <Typography variant="h4">Messages Included In:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DataGrid
                      className={classes.sectionDataGrid}
                      rows={messages}
                      columns={DataGridUtils.MESSAGE_COLUMNS}
                      autoHeight
                      checkboxSelection
                      loading={isLoading}
                      pageSize={10}
                      selectionModel={selectedMessageIds}
                      onSelectionModelChange={handleSelectionModelChange}
                      disableSelectionOnClick
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  return null;
};

const useStyles = makeStyles((theme) => {
  return {
    rootGrid: {
      margin: theme.spacing(2)
    },
    sectionHeaderItemGrid: {
      padding: theme.spacing(2)
    },
    sectionHeaderContainerGrid: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  };
});

export default MessageDetails;
