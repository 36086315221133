/** PACKAGE IMPORTS */
import { setIn } from 'final-form';

const createValidateFromYupSchema = (schema) => async (values) => {
  if (typeof schema === 'function') schema = schema();

  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    return err.inner.reduce((formError, innerError) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});
  }

  return null;
};

export default { createValidateFromYupSchema };
