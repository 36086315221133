/** PACKAGE IMPORTS */
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.rootGrid} container>
      <Grid item xs={12}>
        <Typography variant="h5">
          The content you are looking for cannot be found.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Click <Link to="/">here</Link> to go to the homepage.
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    rootGrid: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: theme.spacing(8),
      textAlign: 'center'
    }
  };
});

export default PageNotFound;
