/** PACKAGE IMPORTS */
import { Grid, Paper, Typography } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

/** ICON PACKAGE IMPORTS */
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';

/** COMPONENT IMPORTS */
import { LinkButton } from '../Components';

/** UTIL IMPORTS */
import { AmplifyUtils, DataGridUtils } from '../utils';

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();

  const [participants, setParticipants] = useState([]);
  const [messageSets, setMessageSets] = useState([]);
  const [messages, setMessages] = useState([]);
  const [messageRecords, setMessageRecords] = useState([]);

  useEffect(() => {
    AmplifyUtils.API.listAll('listParticipants').then(setParticipants);
    AmplifyUtils.API.listAll('listMessageSets').then(setMessageSets);
    AmplifyUtils.API.listAll('listMessages').then(setMessages);
    AmplifyUtils.API.listAll('listMessageRecords').then(setMessageRecords);
  }, []);

  const handleParticipantClick = ({ id }) => history.push(`/participants/${id}`);
  const handleMessageSetClick = ({ id }) => history.push(`/message-sets/${id}`);
  const handleMessageClick = ({ id }) => history.push(`/messages/${id}`);

  return (
    <Grid className={classes.rootGrid} container justify="center">
      <Grid item xs={10}>
        <Grid container justifyContent="center" spacing={4}>
          {/* Participants */}
          <Grid item xs={12}>
            <Paper className={classes.sectionPaper} elevation={4}>
              <Grid className={classes.sectionHeaderContainerGrid} container justify="space-between">
                <Grid item component={Typography} variant="h5">
                  Participants
                </Grid>
                <Grid
                  item
                  component={LinkButton}
                  color="primary"
                  variant="contained"
                  endIcon={<AddCircleOutlineRoundedIcon />}
                  to="/participants/create"
                >
                  Create
                </Grid>
              </Grid>
              <DataGrid
                className={classes.sectionDataGrid}
                rows={participants}
                columns={DataGridUtils.PARTICIPANT_COLUMNS}
                autoHeight
                disableSelectionOnClick
                pageSize={10}
                onCellClick={handleParticipantClick}
              />
            </Paper>
          </Grid>

          {/* Message Sets */}
          <Grid item xs={12} lg={12}>
            <Paper className={classes.sectionPaper} elevation={4}>
              <Grid className={classes.sectionHeaderContainerGrid} container justify="space-between">
                <Grid item component={Typography} variant="h5">
                  Message Sets
                </Grid>
                <Grid
                  item
                  component={LinkButton}
                  color="primary"
                  variant="contained"
                  to="/message-sets/create"
                  endIcon={<AddCircleOutlineRoundedIcon />}
                >
                  Create
                </Grid>
              </Grid>
              <DataGrid
                rows={messageSets}
                columns={DataGridUtils.MESSAGE_SET_COLUMNS}
                autoHeight
                disableSelectionOnClick
                pageSize={5}
                onCellClick={handleMessageSetClick}
              />
            </Paper>
          </Grid>

          {/* Messages */}
          <Grid item xs={12} lg={12}>
            <Paper className={classes.sectionPaper} elevation={4}>
              <Grid className={classes.sectionHeaderContainerGrid} container justify="space-between">
                <Grid item component={Typography} variant="h5">
                  Messages
                </Grid>
                <Grid item component={LinkButton} color="primary" variant="contained" to="/messages/create" endIcon={<AddCircleOutlineRoundedIcon />}>
                  Create
                </Grid>
              </Grid>
              <DataGrid
                rows={messages}
                columns={DataGridUtils.MESSAGE_COLUMNS}
                autoHeight
                disableSelectionOnClick
                pageSize={10}
                onCellClick={handleMessageClick}
              />
            </Paper>
          </Grid>

          {/* Message History */}
          <Grid item xs={12}>
            <Paper className={classes.sectionPaper} elevation={4}>
              <Grid className={classes.sectionHeaderContainerGrid} container justify="space-between">
                <Grid item component={Typography} variant="h5">
                  Message History
                </Grid>
              </Grid>
              <DataGrid
                rows={messageRecords}
                columns={DataGridUtils.MESSAGE_RECORD_COLUMNS}
                autoHeight
                pageSize={5}
                components={{ Toolbar: GridToolbar }}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(4)
  },
  sectionPaper: {},
  sectionHeaderContainerGrid: {
    padding: theme.spacing(2)
  },
  sectionDataGrid: { flex: 1 }
}));

export default Dashboard;
