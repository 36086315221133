const BASE_CONFIG = {
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_region: 'us-east-2',
  aws_cognito_region: 'us-east-2',
  aws_project_region: 'us-east-2'
};

// noinspection SpellCheckingInspection
const awsmobile = {
  dev: {
    ...BASE_CONFIG,
    Auth: {
      identityPoolId: 'us-east-2:d0cf30f4-06de-45a0-ac18-c17b03b90514',
      region: 'us-east-2'
    },
    aws_appsync_graphqlEndpoint: 'https://m7yme5vl3fbplicvnit6ewmu24.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_cognito_identity_pool_id: 'us-east-2:d0cf30f4-06de-45a0-ac18-c17b03b90514',
    aws_user_pools_id: 'us-east-2_6w0Cp9z9I',
    aws_user_pools_web_client_id: '7bo2iojtta5qef8svggd68q6js'
  },
  test: {
    ...BASE_CONFIG,
    Auth: {
      identityPoolId: 'us-east-2:aacd261d-ba59-45c8-afe7-8259d317348a',
      region: 'us-east-2'
    },
    aws_appsync_graphqlEndpoint: 'https://5iuyuqmylvfppfztkm5bz6wrla.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_cognito_identity_pool_id: 'us-east-2:aacd261d-ba59-45c8-afe7-8259d317348a',
    aws_user_pools_id: 'us-east-2_eDgYCtka0',
    aws_user_pools_web_client_id: '7tg2fkv67o9o0apvu6iqpggs90'
  },
  prod: {
    ...BASE_CONFIG,
    Auth: {
      identityPoolId: 'us-east-2:a1876b50-04e1-40ed-8f21-a85ea6808407',
      region: 'us-east-2'
    },
    aws_appsync_graphqlEndpoint: 'https://q5yprud6mfdsjhwtt5qyubsgn4.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_cognito_identity_pool_id: 'us-east-2:a1876b50-04e1-40ed-8f21-a85ea6808407',
    aws_user_pools_id: 'us-east-2_co58EPYVS',
    aws_user_pools_web_client_id: '2k4qe10lqb6cnlcgf7aq15451k'
  }
};

export default awsmobile;
