/** PACKAGE IMPORTS */
import { useContext } from 'react';
import { createBrowserHistory } from 'history';
// noinspection ES6CheckImport
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/** VIEW IMPORTS */
import CreateMessage from './CreateMessage';
import CreateMessageSet from './CreateMessageSet';
import CreateParticipant from './CreateParticipant';
import Dashboard from './Dashboard';
import LandingPage from './LandingPage';
import Loading from './Loading';
import MessageDetails from './MessageDetails';
import MessageSetDetails from './MessageSetDetails';
import PageNotFound from './PageNotFound';
import ParticipantDetails from './ParticipantDetails';

/** COMPONENT IMPORTS */
import { Header } from '../Components';

/** CONTEXT IMPORTS */
import { cognitoContext } from '../Contexts/cognito';
import { loadingContext } from '../Contexts/loading';

/** CONSTANTS */
const BROWSER_HISTORY = createBrowserHistory();
// const HASH_HISTORY = createHashHistory();

const App = () => {
  const { state: cognitoState } = useContext(cognitoContext);
  const { isSignedIn = false } = cognitoState;

  const { state: loadingState } = useContext(loadingContext);
  const { isLoading = false } = loadingState;

  if (isLoading) return <Loading />;

  return (
    <Router history={BROWSER_HISTORY}>
      <Header />
      {isSignedIn ? (
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/message-sets/create" component={CreateMessageSet} />
          <Route exact path="/message-sets/:id" component={MessageSetDetails} />
          <Route exact path="/messages/create" component={CreateMessage} />
          <Route exact path="/messages/:id" component={MessageDetails} />
          <Route exact path="/participants/create" component={CreateParticipant} />
          <Route exact path="/participants/:id" component={ParticipantDetails} />
          <Route component={PageNotFound} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route component={PageNotFound} />
        </Switch>
      )}
    </Router>
  );
};

export default App;
