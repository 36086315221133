import moment from 'moment';

const MAXIMUM_SCHEDULED_MESSAGES = 7;

const areScheduleCronsAtMaximum = (scheduleCrons = [getInitialCron()]) => {
  let numScheduledMessages = 0;

  scheduleCrons.forEach((scheduleCron) => {
    const [, , , , dayWeek = ''] = scheduleCron.split(' ');
    if (dayWeek !== '') numScheduledMessages += dayWeek.split(',').length;
  });

  return numScheduledMessages >= MAXIMUM_SCHEDULED_MESSAGES;
};

const getInitialCron = () => {
  // const hour = moment().hour();
  // const minute = moment().minute();

  return `0 15 * * 0`;
};

/* Only supports comma-separated days in {dayWeek} */
const getPickerValuesFromCron = (cron) => {
  const [minute, hour, , , dayWeek] = cron.split(' ');
  const time = moment().set({ hour, minute }).format('HH:mm');
  const toggledDays = new Array(7).fill(null).map((toggledDay, index) => (dayWeek ?? '').split(',').includes(index.toString(10)));

  return { time, toggledDays };
};

/**
 *
 * @param toggledDays: String - numerical list of days in cron format
 * @param time: String - time value in HH:mm format
 * @returns {`${MINUTE} ${HOUR (24-hour)} * * ${TOGGLED_DAYS (e.g. 0,1,2,5,6)}`}
 */
const getCronFromPickerValues = (toggledDays, time) => {
  const hour = moment(time, 'HH:mm').hour();
  const minute = moment(time, 'HH:mm').minute();
  const dayWeek = toggledDays
    .map((toggledDay, index) => (toggledDay ? index : null))
    .filter((toggledDay) => toggledDay !== null)
    .join(',');

  return `${minute} ${hour} * * ${dayWeek}`;
};

export default {
  MAXIMUM_SCHEDULED_MESSAGES,
  areScheduleCronsAtMaximum,
  getInitialCron,
  getPickerValuesFromCron,
  getCronFromPickerValues
};
