/** PACKAGE IMPORTS */
import { Button, ButtonGroup, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

/** ICON PACKAGE IMPORTS */
import CloseIcon from '@material-ui/icons/Close';

/** COMPONENT IMPORTS */
import TimePicker, { DEFAULT_INITIAL_TIME } from './TimePicker';

/** UTIL IMPORTS */
import { CronUtils } from '../utils';

/** CONSTANTS */
const DAY_LETTERS = ['S', 'M', 'T', 'W', 'R', 'F', 'S'];

/**
 * @param initialValue: Cron - a cron that uses a similar format to "30 12 * * 1,2,4,5"
 * @param onChange: Function - receives param of a new {Cron}
 */
const ScheduleCronPicker = ({ addDaysDisabled = false, initialValue, onChange, onDelete }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * time: String - "HH:mm"
   * toggledDays: Array<Boolean>
   */
  const { time: initialTime, toggledDays: initialToggledDays } = CronUtils.getPickerValuesFromCron(initialValue);
  const [time, setTime] = useState(initialTime ?? DEFAULT_INITIAL_TIME);
  const [toggledDays, setToggledDays] = useState(initialToggledDays ?? new Array(DAY_LETTERS.length).fill(true));

  const handleToggle = (index) => {
    if (addDaysDisabled && !toggledDays[index])
      enqueueSnackbar(`Only ${CronUtils.MAXIMUM_SCHEDULED_MESSAGES} messages can be scheduled per week`, { variant: 'warning' });
    // else if (toggledDays[index] && toggledDays.filter((toggledDay) => toggledDay).length < 2)
    //   enqueueSnackbar('At least one day must be selected', { variant: 'warning' });
    else {
      const newToggledDays = [...toggledDays];

      newToggledDays[index] = !toggledDays[index];
      setToggledDays(newToggledDays);

      onChange(CronUtils.getCronFromPickerValues(newToggledDays, time));
    }
  };

  const handleTimeAccept = (newTime) => {
    setTime(newTime);
    onChange(CronUtils.getCronFromPickerValues(toggledDays, newTime));
  };

  return (
    <Grid className={classes.rootGrid} container alignItems="center" justify="center">
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        {DAY_LETTERS.map((dayLetter, index) => (
          <Button className={classes.dayToggleButton} variant={toggledDays[index] ? 'contained' : 'outlined'} onClick={() => handleToggle(index)}>
            {dayLetter}
          </Button>
        ))}
      </ButtonGroup>
      <Typography className={classes.centerTextBox} variant="h6">
        at
      </Typography>
      <TimePicker initialTime={time} onAccept={handleTimeAccept} />
      <IconButton className={classes.deleteButton} color="secondary" onClick={onDelete}>
        <CloseIcon />
      </IconButton>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    marginBottom: theme.spacing(2)
  },
  dayToggleButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  centerTextBox: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  deleteButton: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  }
}));

export default ScheduleCronPicker;
