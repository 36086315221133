/** PACKAGE IMPORTS */
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import { TextField } from 'final-form-material-ui';
import { Field, Form } from 'react-final-form';
// noinspection ES6CheckImport
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

/** ICON PACKAGE IMPORTS */
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

/** GRAPHQL IMPORTS */
import { createMessageSet } from '../graphql/mutations';

/** UTIL IMPORTS */
import { ConsoleUtils, YupUtils } from '../utils';

const CREATE_MESSAGE_SET_VALIDATE = YupUtils.createValidateFromYupSchema(
  yup.object({
    name: yup.string().required(),
    description: yup.string()
  })
);

const CreateMessageSetForm = ({ handleSubmit, submitting }) => {
  const classes = useStyles();
  const history = useHistory();
  const { goBack } = history;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container>
        <Grid className={classes.messageSetFormTextField} item xs={12}>
          <Field fullWidth required component={TextField} type="text" label="Name" name="name" variant="outlined" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid className={classes.messageSetFormTextField} item xs={12}>
          <Field fullWidth required component={TextField} type="text" label="Description" name="description" multiline rows={8} variant="outlined" />
        </Grid>
      </Grid>
      <Grid className={classes.signInFormContainerGrid} container justify="flex-end" spacing={2}>
        <Grid item>
          <Button variant="contained" color="primary" type="button" disabled={submitting} startIcon={<CloseIcon />} onClick={goBack}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" type="submit" disabled={submitting} startIcon={<DoneIcon />}>
            Create
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const CreateMessageSet = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleCreateMessageSet = async ({ name, description }) => {
    try {
      const createMessageSetResult = await API.graphql(graphqlOperation(createMessageSet, { input: { name, description } }));
      const { id } = createMessageSetResult?.data?.createMessageSet ?? {};

      if (id) history.push(`/message-sets/${id}`);
      else history.goBack();
    } catch (e) {
      ConsoleUtils.log(e);
    }
  };

  return (
    <Grid className={classes.rootGrid} container justify="center">
      <Grid item xs={6}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12}>
            <Paper className={classes.contentPaper} elevation={4}>
              <Grid className={classes.sectionHeaderContainerGrid} container justify="center">
                <Typography variant="h4">Create Message Set</Typography>
              </Grid>
              <Form validate={CREATE_MESSAGE_SET_VALIDATE} render={CreateMessageSetForm} onSubmit={handleCreateMessageSet} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    rootGrid: {
      marginTop: theme.spacing(4)
    },
    contentPaper: {
      padding: theme.spacing(4)
    },
    messageSetFormTextField: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2)
    }
  };
});

export default CreateMessageSet;
