/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      text
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageSet = /* GraphQL */ `
  query GetMessageSet($id: ID!) {
    getMessageSet(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listMessageSets = /* GraphQL */ `
  query ListMessageSets(
    $filter: ModelMessageSetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getParticipant = /* GraphQL */ `
  query GetParticipant($id: ID!) {
    getParticipant(id: $id) {
      id
      fullName
      phoneNumber
      status
      scheduleCrons
      lastMessageRecordId
      createdAt
      updatedAt
    }
  }
`;
export const listParticipants = /* GraphQL */ `
  query ListParticipants(
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        phoneNumber
        status
        scheduleCrons
        lastMessageRecordId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageSetMessage = /* GraphQL */ `
  query GetMessageSetMessage($id: ID!) {
    getMessageSetMessage(id: $id) {
      id
      messageId
      messageSetId
      createdAt
      updatedAt
    }
  }
`;
export const listMessageSetMessages = /* GraphQL */ `
  query ListMessageSetMessages(
    $filter: ModelMessageSetMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageSetMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageId
        messageSetId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageSetSubscription = /* GraphQL */ `
  query GetMessageSetSubscription($id: ID!) {
    getMessageSetSubscription(id: $id) {
      id
      participantId
      messageSetId
      createdAt
      updatedAt
    }
  }
`;
export const listMessageSetSubscriptions = /* GraphQL */ `
  query ListMessageSetSubscriptions(
    $filter: ModelMessageSetSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageSetSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        participantId
        messageSetId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageRecord = /* GraphQL */ `
  query GetMessageRecord($id: ID!) {
    getMessageRecord(id: $id) {
      id
      status
      participantFullName
      participantPhoneNumber
      messageText
      participantId
      messageId
      createdAt
      updatedAt
    }
  }
`;
export const listMessageRecords = /* GraphQL */ `
  query ListMessageRecords(
    $filter: ModelMessageRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        participantFullName
        participantPhoneNumber
        messageText
        participantId
        messageId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
