import { Tooltip, Typography } from '@material-ui/core';

/* FUNCTIONS */
const addTooltipToColumn = (column) => {
  return {
    ...column,
    renderCell: ({ row }) => (
      <Tooltip title={row[column.field]} enterDelay={500}>
        <Typography variant="body2" noWrap>
          {row[column.field]}
        </Typography>
      </Tooltip>
    )
  };
};

const addTooltipToColumns = (columns = []) => columns.map((column) => addTooltipToColumn(column));

/* PRESET COLUMN */
const ID_COLUMN = {
  field: 'id',
  headerName: 'ID'
};

/* PRESET COLUMNS */
const MESSAGE_COLUMNS = addTooltipToColumns([
  ID_COLUMN,
  {
    field: 'text',
    headerName: 'Text',
    flex: 1
  }
]);

const MESSAGE_SET_COLUMNS = addTooltipToColumns([
  ID_COLUMN,
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'description', headerName: 'Description', flex: 2 }
]);

const MESSAGE_RECORD_COLUMNS = addTooltipToColumns([
  ID_COLUMN,
  // { field: 'createdAt', headerName: 'Created At', flex: 1  },
  { field: 'updatedAt', headerName: 'Updated At', flex: 1  },
  { field: 'status', headerName: 'Status', flex: 1  },
  { field: 'participantFullName', headerName: 'Name', flex: 1  },
  { field: 'participantPhoneNumber', headerName: 'Phone number', flex: 1  },
  { field: 'messageText', headerName: 'Text', flex: 2 }
]);

const PARTICIPANT_COLUMNS = addTooltipToColumns([
  ID_COLUMN,
  {
    field: 'fullName',
    headerName: 'Name',
    flex: 1
  },
  { field: 'status', headerName: 'Status', flex: 2 },
  { field: 'phoneNumber', headerName: 'Phone number', flex: 2 }
]);

const PARTICIPANT_MESSAGE_RECORD_COLUMNS = addTooltipToColumns([
  ID_COLUMN,
  // { field: 'createdAt', headerName: 'Created At', flex: 1 },
  { field: 'updatedAt', headerName: 'Updated At', flex: 1 },
  { field: 'status', headerName: 'Status', flex: 1 },
  { field: 'messageText', headerName: 'Text', flex: 2 }
]);

export default {
  addTooltipToColumn,
  addTooltipToColumns,
  ID_COLUMN,
  MESSAGE_COLUMNS,
  MESSAGE_SET_COLUMNS,
  MESSAGE_RECORD_COLUMNS,
  PARTICIPANT_COLUMNS,
  PARTICIPANT_MESSAGE_RECORD_COLUMNS
};
