/** PACKAGE IMPORTS */
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/** ASSET IMPORTS */
import { LandingBannerJPEG } from '../assets/images';

const CHROME_BACKGROUND_COLOR = '#FAFAFA';

const LandingPage = () => {
  const classes = useStyles();

  // const handleSignInPress = () => {};

  return (
    <Grid className={classes.rootGrid} container justify="center">
      <Grid className={classes.headerGridItem} item xs={6}>
        <Grid container justifyContent="center">
          <Typography variant="h4" color="primary">
            <Box fontWeight="fontWeightBold">FACES Medication Adherence</Box>
          </Typography>
        </Grid>
        <Grid container justifyContent="center">
          <Typography variant="h6" color="primary">
            <Box fontStyle="italic" fontWeight="fontWeightLight">
              Manage participants and messages for medication adherence
            </Box>
          </Typography>
        </Grid>
        {/* <Grid className={classes.signInButtonContainer} container justifyContent="flex-end"> */}
        {/*  <Button variant="outlined" color="primary" onPress={handleSignInPress}>Sign In</Button> */}
        {/* </Grid> */}
      </Grid>
      <svg className={classes.backgroundSVG} height="100%" width="100%" viewBox="0 0 500 150" preserveAspectRatio="none">
        <path fill={CHROME_BACKGROUND_COLOR} d="M0,100 C150,150 300,50 500,115 L500,150 L0,150 Z" />
      </svg>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    backgroundImage: `url(${LandingBannerJPEG})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    width: '100vw'
  },
  headerGridItem: {
    marginTop: theme.spacing(8)
  },
  signInButtonContainer: {
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(4)
  },
  backgroundSVG: {
    position: 'absolute',
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    width: '100vw'
  }
}));

export default LandingPage;
