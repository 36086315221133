/** PACKAGE IMPORTS */
import { Auth } from 'aws-amplify';
import { AppBar, Box, Button, Dialog, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from 'final-form-material-ui';
import { useContext, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

/** ICON PACKAGE IMPORTS */
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import LockOpenIcon from '@material-ui/icons/LockOpen';

/** CONTEXT IMPORTS */
import { cognitoContext } from '../Contexts/cognito';

/** UTIL IMPORTS */
import { YupUtils } from '../utils';

/** CONSTANTS */
const SIGN_IN_INITIAL_VALUES = { email: '', password: '' };
const SIGN_IN_VALIDATE = YupUtils.createValidateFromYupSchema(
  yup.object({
    email: yup.string().email().required(),
    password: yup.string().required()
  })
);
const CHANGE_PASSWORD_VALIDATE = YupUtils.createValidateFromYupSchema(
  yup.object({
    email: yup.string().email().required(),
    newPassword: yup.string().required()
  })
);

const SignInForm = ({ handleSubmit, onCancel, submitting }) => {
  const classes = useStyles();

  return (
    <Box margin={4} marginBottom={2} marginTop={0}>
      <form onSubmit={handleSubmit} noValidate>
        <Grid className={classes.formContainerGrid} container>
          <Grid className={classes.formGridItem} item xs={12}>
            <Field fullWidth required component={TextField} type="email" label="Email" name="email" />
          </Grid>
          <Grid className={classes.formGridItem} item xs={12}>
            <Field fullWidth required component={TextField} type="password" label="Password" name="password" />
          </Grid>
        </Grid>
        <Grid className={classes.formContainerGrid} container justify="flex-end" spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" disabled={submitting} startIcon={<CloseIcon />} onClick={onCancel}>
              Close
            </Button>
          </Grid>
          <Grid className={classes.formGridItem} item>
            <Button variant="contained" color="primary" type="submit" disabled={submitting} startIcon={<LockOpenIcon />}>
              Sign In
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const SignOutForm = ({ handleSubmit, onCancel, submitting }) => {
  const classes = useStyles();

  return (
    <Box margin={4} marginBottom={2} marginTop={2}>
      <Typography variant="body1">Are you sure you want to sign out?</Typography>
      <form onSubmit={handleSubmit} noValidate>
        <Grid className={classes.formContainerGrid} container justify="flex-end" spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" disabled={submitting} startIcon={<CloseIcon />} onClick={onCancel}>
              Close
            </Button>
          </Grid>
          <Grid className={classes.formGridItem} item>
            <Button variant="contained" color="primary" type="submit" disabled={submitting} startIcon={<SignOutIcon />}>
              Sign Out
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const ChangePasswordForm = ({ handleSubmit, onCancel, submitting }) => {
  const classes = useStyles();

  return (
    <Box margin={4} marginBottom={2} marginTop={0}>
      <form onSubmit={handleSubmit} noValidate>
        <Grid className={classes.formContainerGrid} container>
          <Grid className={classes.formGridItem} item xs={12}>
            <Field fullWidth required component={TextField} type="email" label="Email" name="email" />
          </Grid>
          <Grid className={classes.formGridItem} item xs={12}>
            <Field fullWidth required component={TextField} type="password" label="New Password" name="newPassword" />
          </Grid>
        </Grid>
        <Grid className={classes.formContainerGrid} container justify="flex-end" spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" disabled={submitting} startIcon={<CloseIcon />} onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid className={classes.formGridItem} item>
            <Button variant="contained" color="primary" type="submit" disabled={submitting} startIcon={<DoneIcon />}>
              Change Password
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const AuthDialog = ({ errorCode = null, initialValues = SIGN_IN_INITIAL_VALUES, onCancel, onSubmit, open = false, type = 'SIGN_IN' }) => {
  const ErrorMessage = () =>
    errorCode ? (
      <Box margin={4} marginBottom={0} marginTop={2}>
        <Typography color="secondary" variant="paragraph">
          {/* Password does not conform to policy: Password not long enough */}
          {/* Password does not conform to policy: Password must have uppercase characters */}
          {/* Password does not conform to policy: Password must have numeric characters */}
          {errorCode === 'InvalidPasswordException' && 'Password must be at least 10 characters and contain 1 uppercase and 1 number.'}
          {errorCode === 'NotAuthorizedException' && 'Incorrect username or password.'}
          {errorCode === 'UserNotFoundException' && 'User does not exist.'}
        </Typography>
      </Box>
    ) : null;

  if (type === 'CHANGE_PASSWORD')
    return (
      <Dialog onClose={onCancel} aria-labelledby="change-password-dialog" open={open}>
        <ErrorMessage />
        <Form validate={CHANGE_PASSWORD_VALIDATE} initialValues={initialValues} render={ChangePasswordForm} onCancel={onCancel} onSubmit={onSubmit} />
      </Dialog>
    );

  if (type === 'SIGN_IN')
    return (
      <Dialog onClose={onCancel} aria-labelledby="sign-in-dialog" open={open}>
        <ErrorMessage />
        <Form validate={SIGN_IN_VALIDATE} initialValues={initialValues} render={SignInForm} onCancel={onCancel} x onSubmit={onSubmit} />
      </Dialog>
    );

  if (type === 'SIGN_OUT')
    return (
      <Dialog onClose={onCancel} aria-labelledby="sign-out-dialog" open={open}>
        <ErrorMessage />
        <Form render={SignOutForm} onCancel={onCancel} onSubmit={onSubmit} />
      </Dialog>
    );

  return null;
};

const Header = () => {
  const classes = useStyles();

  const { dispatch: cognitoDispatch, state: cognitoState } = useContext(cognitoContext);
  const { isSignedIn, user } = cognitoState;

  const [credentials, setCredentials] = useState(SIGN_IN_INITIAL_VALUES);
  const [dialogType, setDialogType] = useState('SIGN_IN');
  const [errorCode, setErrorCode] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  /* If dialog changes, reset {errorCode} */
  useEffect(() => {
    setErrorCode(null);
  }, [credentials, dialogType, isDialogOpen]);

  const handleSignInClick = () => {
    setCredentials(SIGN_IN_INITIAL_VALUES);
    setDialogType('SIGN_IN');
    setIsDialogOpen(true);
  };

  const handleSignOutClick = () => {
    setDialogType('SIGN_OUT');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => setIsDialogOpen(false);

  const handleChangePassword = async ({ newPassword }) => {
    try {
      const newUser = await Auth.completeNewPassword(user, newPassword);

      cognitoDispatch({ type: 'SIGN_IN', fromLoading: false, user: newUser });
      setIsDialogOpen(false);
    } catch (e) {
      setErrorCode(e?.code);
    }
  };

  const handleSignIn = async ({ email, password }) => {
    try {
      setCredentials({ email, password });

      const lowerCaseEmail = (email ?? '').toLowerCase();
      const newUser = await Auth.signIn(lowerCaseEmail, password);
      if (newUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        cognitoDispatch({ type: 'CHANGE_PASSWORD', user: newUser });
        setDialogType('CHANGE_PASSWORD');
      } else {
        cognitoDispatch({ type: 'SIGN_IN', fromLoading: false, user: newUser });
        setIsDialogOpen(false);
      }
    } catch (e) {
      setErrorCode(e?.code);
    }
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut();

      cognitoDispatch({ type: 'SIGN_OUT' });
      setIsDialogOpen(false);
      setErrorCode(null);
    } catch (e) {
      setErrorCode(e?.code);
    }
  };

  /* Handler props */
  const onClick = isSignedIn ? handleSignOutClick : handleSignInClick;

  const onSubmit = {
    CHANGE_PASSWORD: handleChangePassword,
    SIGN_IN: handleSignIn,
    SIGN_OUT: handleSignOut
  }[dialogType];

  return (
    <AppBar className={classes.rootGrid} position="static">
      <Toolbar>
        <Grid container className={classes.containerGrid}>
          <Button component={Link} to="/">
            <Typography variant="h4" className={classes.title}>
              FACES
            </Typography>
          </Button>
          <IconButton className={classes.authButton} component={isSignedIn ? SignOutIcon : Button} onClick={onClick}>
            {!isSignedIn && <Typography variant="h6">SIGN IN</Typography>}
          </IconButton>
        </Grid>
      </Toolbar>
      <AuthDialog
        type={dialogType}
        open={isDialogOpen}
        errorCode={errorCode}
        initialValues={credentials}
        onCancel={handleCloseDialog}
        onSubmit={onSubmit}
      />
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    rootGrid: {},
    containerGrid: {
      flexDirection: 'row',
      paddingRight: theme.spacing(2),
      justifyContent: 'space-between'
    },
    signInButtonGridItem: {},
    title: {
      color: theme.palette.primary.contrastText
    },
    link: {
      color: theme.palette.primary.contrastText
    },
    /* Sign in dialog */
    formPaper: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(8)
    },
    /* Sign in form */
    formContainerGrid: {
      justifyContent: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    formField: {},
    formGridItem: {},
    authDialog: { margin: theme.spacing(8) },
    authButton: { color: theme.palette.primary.contrastText }
  };
});

export default Header;
